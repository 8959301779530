export const getFormatFecha = (fecha) => {

    const date = new Date(fecha)
    let dia = date.getDate();
    let anio = date.getUTCFullYear();
    let mesDos = ("0" + (date.getMonth() + 1)).slice(-2);

    let fechaHoy = dia.toString().padStart(2, "0") + '-' + mesDos + '-' + anio;

    return fechaHoy.toString()
}

export const getFormatMonthYear = (fecha) => {

    const date = new Date(fecha)
    let mes = date.getMonth();
    let anio = date.getUTCFullYear();

    let fechaHoy = months[mes] + '-' + anio;

    return fechaHoy.toString()
}

export const getFormatInputFecha = (fecha) => {

    const date = new Date(fecha)
    let dia = date.getDate();
    let anio = date.getUTCFullYear();
    let mesDos = ("0" + (date.getMonth() + 1)).slice(-2);

    let fechaHoy = anio + '-' + mesDos + '-' + dia.toString().padStart(2, "0");

    return fechaHoy.toString()
}

export const getFormatFechaLong = (data) => {
    const opciones = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(data).toLocaleDateString('Es-es', opciones)
}
export const getFormatHora = (data) => {

    const date = new Date(data)
    let horas = date.getHours();
    let minutos = date.getMinutes();


    let horaActual = `${horas.toString().padStart(2,"0")}: ${minutos.toString().padStart(2,"0")}`
    return horaActual
}

const months =
    ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
        'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']