import axios from "axios";


const bdaxios = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
});


export class Voto {


    idVillage = ''
    idSong = 1
    dirip = ''


    async getVotes() {
        try {
            const res = await bdaxios.get('/votes')
            return res.data;
        } catch (error) {
            if (error.response.status === 404)
                throw new Error(error.response.data.message)
            throw new Error(error.message)
        }
    }
    async getVotesVillage(id) {
        try {
            const res = await bdaxios.get('/votes/'+ id)
            return res.data;
        } catch (error) {
            if (error.response.status === 404)
                throw new Error(error.response.data.message)
            throw new Error(error.message)
        }
    }

    async addVote() {
        try {
            await bdaxios({
                method: 'POST',
                url: '/votes',            
                data: this
            });
        } catch (error) {
            if (error.response.status === 409)
                throw (error.response.data)
            throw new Error(error.message)
        }
        return this.getVotes();
    }

    async updateVote(vote, token) {
        await bdaxios({
            method: 'PATCH',
            url: '/votes/' + vote.id,
            headers: {
                Authorization: 'Bearer ' + token.value,
            },
            data: vote
        });
        return this.getVotes();
    }

    async delVote() {
        await bdaxios({
            method: 'DELETE',
            url: '/votes',
            data: this
        });
        return this.getVotes();
    }
}