import { useContext, useEffect, useState } from 'react';
import { ContextApp } from '../../context/ContextApp';

import './lstSong.css'
import SongComponent from '../song/SongComponent';
import EndVoteComponent from '../modalEndVote/EndVoteComponent';

const LstSongComponent = ({ setShow, artist }) => {

    const { cancionClass, votos, actualizarVotos } = useContext(ContextApp);

    const [songs, setSongs] = useState([]);
    const [lstVotos, setLstVotos] = useState();
    const [showEnd, setShowEnd] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            setSongs(await cancionClass.getArtistSongs(artist.ART_CLAVE));
            setLstVotos(await actualizarVotos());
        }
        loadData();

    }, [artist, votos]);

    const cerrar = (e) =>{
        const { name } = e.target.attributes;      
        if(name && name.value==='blur') setShow(false);
    }

    return (
        <div name='blur' className='lstContainer' onClick={cerrar}>
            <div className='modal-popup' >
                <section className='lst-header'>
                    <h2>Temas de {artist.ART_NOMBRE}</h2>
                    <button className='btn-close' onClick={() => setShow(false)}>X</button>
                </section>

                <section className='lst-body' >
                    {
                        songs ?
                            songs.map((song) => (
                                <SongComponent key={song.CAN_CLAVE}
                                    song={song}
                                    votes={lstVotos}
                                    setShowEnd={setShowEnd}
                                />
                            ))
                            : <h3>No hay canciones de {artist.ART_NOMBRE} en la BBDD.</h3>
                    }
                    {
                        showEnd && <EndVoteComponent setShowEnd={setShowEnd}/>
                    }
                </section>

                <section className='lst-footer'>
                    {/* <h2>Sitio para el boton de aceptar</h2> */}
                </section>
            </div>

        </div>
    )
}
export default LstSongComponent