import { useEffect, useState, useContext } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { ContextApp } from "../../context/ContextApp"
import PrevVotesComponent from '../../components/CloseVotes/PrevVotesComponent';
import PostVotesComponent from '../../components/CloseVotes/PostVotesComponent';
import ArtistComponent from '../../components/artista/ArtistComponent'
import LstSongComponent from '../../components/lstSongs/LstSongComponent'
import Loader from '../../components/loader/Loader';

import './main.css'

const Main = () => {

  const { id, pueblo, artistaClass, datosClass, actualizarVotos } = useContext(ContextApp);

  const [openVotes, setOpenVotes] = useState(2)
  const [artists, setArtists] = useState(null)
  const [show, setShow] = useState(false);
  const [votes, setVotes] = useState([]);
  const [artist, setArtist] = useState(null);


  useEffect(() => {

    const loadData = async () => {
      const fecha = new Date();
      setArtists(await artistaClass.getArtists())
      setVotes(await actualizarVotos());


      if (pueblo) {
        if (fecha < new Date(pueblo.PUE_FECOPEN)) {
          setOpenVotes(-1);
          return;
        } else if (fecha > new Date(pueblo.PUE_FECCLOSE)) {
          setOpenVotes(1);
          return;
        } else {
          setOpenVotes(0);
        }
      }
    }
    loadData()
  }, [id, pueblo, datosClass, artistaClass])




  const showListSongs = (artist) => {
    setShow(true)
    setArtist(artist)
  }

  return (

    <div className='container'>
      {(pueblo && artists) ?
        <>

          {openVotes === -1 &&
            <PrevVotesComponent pueblo={pueblo} />}

          {openVotes === 1 &&
            <PostVotesComponent />}

          {openVotes === 0 &&
            <>
              <p className='mensaje'>
                Puedes elegir tus {process.env.REACT_APP_VOTES_NUMBER} canciones favoritas entre los siguientes artistas.
              </p>
              
                <Link to={'/results'} >Ver votaciones</Link>
              
              <div className='container-artists'>
                {artists.map((artist) => (
                  <ArtistComponent
                    key={artist.ART_CLAVE}
                    artist={artist}
                    lstVotos={votes}
                    showListSongs={showListSongs} />
                ))}
              </div>

              {/* Contenedor para todos las canciones */}
              {show &&
                <LstSongComponent setShow={setShow} artist={artist} />
              }
            </>
          }
        </>
        :
        <div className='container-loader'>
          <Loader />
        </div>
      }
    </div>

  )
}
export default Main