import './endvote.css'

const EndVoteComponent = ({ setShowEnd }) => {

    return (
        <div className='endContainer'>
            <div className='end-modal-popup' >
                <section className='end-header'>
                    <h2>YA HAS REALIZADO LOS CINCO VOTOS</h2>
                    <button className='btn-close' onClick={() => setShowEnd(false)}>X</button>
                </section>
                <section className='end-body' >
                    <p>Si lo deseas puedes modificar tus votos. </p>
                </section>
            </div>
        </div>
    )
}
export default EndVoteComponent