import { useContext } from 'react';
import { ContextApp } from '../../context/ContextApp';
import { getFormatFecha } from '../../helpers/dates';

const PrevPage = () => {


  const { pueblo } = useContext(ContextApp);

  return (
    <div className="container non">

      <h2 className='textvillage'> Las votaciones todavía no esta abiertas. </h2>
      <h2 className='textvillage'> Se abrirán el  {getFormatFecha(pueblo.PUE_FECOPEN)}</h2>
      
      <div className='logo-container'>
        <img src={require('../../assets/logoPoptelera.png')} alt="Logo Poptelera" />
      </div>
    </div>
  )
}
export default PrevPage