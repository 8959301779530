import './home.css'

const Home = () => {
  return (
    <div className="container non">

      <div className='logo-container'>
        <img src={require('../../assets/logoPoptelera.png')} alt="Logo Poptelera" />
      </div>

      <h1> Busca tu actuación para poder votar. </h1>
    </div>
  )
}
export default Home