import { getFormatFecha } from "../../helpers/dates"
import './titleVillage.css'

const TitleVillage = ({ pueblo }) => {

  return (
    <div className='title'>
      <h1>{pueblo.PUE_NOMBRE}</h1>
      {pueblo &&
        <section className='datas'>
          <h2>
            <span>
              {pueblo.PUE_UBICACION}.
            </span>
            <span>
              ({getFormatFecha(pueblo.PUE_FECACT)} a las {pueblo.PUE_HORA}H)
            </span>
          </h2>
        </section>
      }
    </div>
  )
}
export default TitleVillage