import { useContext } from 'react';
import { ContextApp } from '../../context/ContextApp';

const PostPage = () => {

    const { pueblo } = useContext(ContextApp);

  return (
    <div className="container non">

      <h2 className='textvillage'> Las votaciones ya se han cerrado. </h2>
      
      <div className='logo-container'>
        <img src={require('../../assets/logoPoptelera.png')} alt="Logo Poptelera" />
      </div>
    </div>
  )
}
export default PostPage