import { createContext, useEffect, useState } from "react";
import { Artista } from "../controllers/ArtistsController";
import { Cancion } from "../controllers/SongsController";
import { Pueblo } from "../controllers/VillagesController";
import { Voto } from "../controllers/VotesController";
import { Datos } from "../controllers/DatasController";
import { getDataIp } from "../helpers/miIp";
import { useLocation, useNavigate } from "react-router-dom";


export const ContextApp = createContext();

export const AppProvider = ({ children }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [id] = useState(location.pathname.substring(1))


  const artistaClass = new Artista();
  const cancionClass = new Cancion();
  const votoClass = new Voto();
  const datosClass = new Datos();


  const [ip, setIP] = useState();
  const [votos, setVotos] = useState([]);
  const [pueblo, setPueblo] = useState();

  useEffect(() => {
    const loadIp = async () => {
      setIP(await getDataIp());
    }
    loadIp()
  }, [])

  useEffect(() => {
    const loadData = async () => {
      try {
        setPueblo(await new Pueblo().getVillage(id))
        pueblo && setVotos(await actualizarVotos())

      } catch (error) {
        console.log(error.message)
        navigate('/');
      }
    }
    loadData()
  }, [ip])

  const addVoto = async (idSong) => {
    votoClass.idVillage = id;
    votoClass.dirip = ip;
    votoClass.idSong = idSong;
    votoClass.addVote();
    setVotos(await actualizarVotos())
  }
  const delVoto = async (idSong) => {
    votoClass.idVillage = id;
    votoClass.dirip = ip;
    votoClass.idSong = idSong;
    votoClass.delVote();
    setVotos(await actualizarVotos())
  }

  const actualizarVotos = async () => {
    try {
      const votosIp = await votoClass.getVotesVillage(id)
      const votosFilterIp = votosIp && await votosIp.filter(vot => vot.VOT_DIRIP === ip)
      return votosFilterIp;
    } catch (error) {
      return []
    }
  }


  return (
    <ContextApp.Provider value={{
      id,    
      pueblo,
      votos,
      artistaClass,
      cancionClass,
      datosClass,
      addVoto,
      delVoto,
      actualizarVotos
    }}>
      {children}
    </ContextApp.Provider>
  )
}