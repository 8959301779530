import './loader.css'
const Loader = () => {
  return (
    <>
    <div className="loader">
    </div>
      <h1>Cargando...</h1>
    </>
  )
}
export default Loader