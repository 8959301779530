import axios from "axios";


const bdaxios = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
});


export class Datos {

    async getTotalsData() {
        const res = await bdaxios.get('/totals');
        return res.data;
    }

    async getTotalsVotes(id) {

        try {     
            const res = await bdaxios({
                method: 'GET',
                url: '/votes/resultsfill/' + id,
            });
            return res.data
        } catch (error) {
            throw error
        }

    }



}