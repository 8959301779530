import { useContext, useEffect, useState } from 'react';
import CounterController from '../counter/CounterController'
import TitleVillage from '../title/TitleVillage'
import { ContextApp } from '../../context/ContextApp';
import './header.css'

const Header = () => {

  const { pueblo, votos, actualizarVotos } = useContext(ContextApp);

  const [lstVotos, setLstVotos] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      setLstVotos(await actualizarVotos());
    }
    loadData();

}, [votos]);

  return (
    <>
      {(pueblo && lstVotos) &&
      <div className='header-container'>

        <div className='header'>
          <img className='logoLeft' src={require('../../assets/logoPoptelera.png')} alt="Logo Poptelera" />
          <TitleVillage className='title' pueblo={pueblo} />

          <img className='logoRight' src={require('../../assets/logoPoptelera.png')} alt="Logo Poptelera" />
        </div>
          <CounterController lstVotos={lstVotos} />
          
      </div>
      }
    </>
  )
}

export default Header
