import { Link } from 'react-router-dom';
import './CloseVotes.css'

const PostVotesComponent = () => {
    return (
        <div className="container non">

            <h2 className='textvillage'> Las votaciones ya se han cerrado. </h2>

            <div className='logo-container'>
                <img src={require('../../assets/logoPoptelera.png')} alt="Logo Poptelera" />
            </div>
            <Link className='btnLink' to={'/results'} >Ver los resultados</Link>
        </div>
    )
}
export default PostVotesComponent