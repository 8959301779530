
import { useContext, useEffect, useState } from 'react'
import { ContextApp } from '../../context/ContextApp';

import './song.css'


const SongComponent = ({ song, votes, setShowEnd }) => {

    const { addVoto, delVoto } = useContext(ContextApp);


    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        const loadData = (async () => {
            setIsChecked(false);
            votes && votes.forEach(item => {
                if (song.CAN_CLAVE === item.VOT_CLACAN) {
                    setIsChecked(true);
                    return;
                }
            });
        });
        loadData();
    }, [votes, isChecked])

    const handleClick = async (e) => {
        const { checked } = e.target;

        if (checked) {
            if(votes.length >=process.env.REACT_APP_VOTES_NUMBER ) return setShowEnd(true)
            await addVoto(song.CAN_CLAVE)
            
        } else {
            delVoto(song.CAN_CLAVE)
        }
    };

    return (
        <div className='song-container'>
            <p>{song.CAN_TITULO}</p>

            <input
                checked={isChecked}
                id="checkvot"
                type='checkbox'
                name='checkvot'
                onChange={handleClick}
            />
        </div>
    )
}
export default SongComponent