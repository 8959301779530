import Router from './routes/Router';
import Header from './components/header/Header';

import './App.css';
import FooterComponent from './components/footer/FooterComponent';

function App() {
  return (
    <div className='App'>
      <Header />
      <Router />
      <FooterComponent />
    </div>
  );
}

export default App;
