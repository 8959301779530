import { Routes, Route } from 'react-router-dom';
import Main from '../pages/main/Main';
import Home from '../pages/home/Home';
import PrevPage from '../pages/prevpage/PrevPage';
import PostPage from '../pages/postpage/PostPage';
import Results from '../pages/results/Results';

const Router = () => {
  return (
    
    <Routes>
        <Route path='/' element={ <Home/> }/>
        <Route path='/results' element={ <Results/> }/>
        <Route path='/voteprev' element={ <PrevPage/> }/>
        <Route path='/closedvote' element={ <PostPage/> }/>
        <Route path='/:id' element={ <Main/> }/>
        {/* <Route path='*' element={ <Home/> }/> */}

    </Routes>
  )
}
export default Router