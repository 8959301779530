import axios from "axios";

export const getDataIp = async () => {
  try {
    
    // const res = await axios.get('https://geolocation-db.com/json/')
    const res = await axios.get('https://api.ipify.org/?format=json')
    
    return(res.data.ip)
  } catch (error) {
    console.log(error.message)
  }
  }