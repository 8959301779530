import { useContext } from 'react';
import { ContextApp } from '../../context/ContextApp';
import './footer.css'


const FooterComponent = () => {

    const { ip } = useContext(ContextApp);

    return (
        <div className='footer-container'>

            <section className='footer-text'>
                <h3>ByJIM©2022</h3>
                <h4>José Ignacio Martínez</h4>
            </section>

            <section className='footer-ip'>
                {ip && <p>IP: { ip }</p> }
            </section>
            
            <section className='footer-logo'>
                <img src={require('../../assets/poptelera-linea.png')} alt="Logo Poptelera" />

            </section>
        </div>
    )
}

export default FooterComponent
