import axios from "axios";


const bdaxios = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
});


export class Cancion {
    
    id = ''
    titulo = ''
    visible = 1
    duracion = '03:45'
    anyo = 1975
    idArtista = 1

    async getSongs() {
        try {
            const res = await bdaxios({
                method:'GET',
                url: '/songs/lean',
            });
            console.log(res.data)
            return res.data;

        } catch (error) {
            console.log(error.message)
            throw error
        }
    }

    async getArtistSongs(id) {
        try {
            const res = await bdaxios({
                method:'GET',
                url: '/songs/artist/' + id,
            });
            if(res.status===404) return null
            return res.data;

        } catch (error) {
            // console.log(error.message)
            return null;
            // throw error
        }
    }


    async addSong(song, token) {
        try {     
            await bdaxios({
                method: 'POST',
                url: '/songs',
                headers: {
                    Authorization: 'Bearer ' + token.value,
                },
                data: song
            });
            return this.getSongs();
        } catch (error) {
            throw error
        }
    }

    async updateSong(song, token) {
        await bdaxios({
            method: 'PATCH',
            url: '/songs/' + song.id,
            headers: {
                Authorization: 'Bearer ' + token.value,
            },
            data: song
        });
        return this.getSongs();
    }

    async delSong(id, token) {
        await bdaxios({
            method: 'DELETE',
            url: '/songs/' + id,
            headers: {
                Authorization: 'Bearer ' + token.value,
            },
        });
        return this.getSongs();
    }
}