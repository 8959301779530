import axios from "axios";

const bdaxios = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
});


export const goVillage = async (req, res) => {
    const { id } = req.params;

};


export class Pueblo {

    id = ''
    nombre = 'Litago'
    provincia = 'Zaragoza'
    ubicacion = 'Plaza'
    hora = '22:30'
    fecact = "2022-11-29"
    fecopen = "2022-11-26"
    fecclose = '2022-11-27'


    async getVillage(id) {
        try {
            if(id===undefined || id==='') id = 'x';
            const res = await bdaxios({
                method: 'GET',
                url: '/villages/' + id,
            })
            return res.data;
        } catch (error) {
            if (error.response.status === 404)
                throw new Error(error.response.data.message)
            throw new Error(error.message)
        }

    }

    async getVillages() {
        try {
            const res = await bdaxios.get('/villages')
            return res.data;
        } catch (error) {
            if (error.response.status === 404)
                throw new Error(error.response.data.message)
            throw new Error(error.message)
        }

    }
    async addVillage(village, token) {
        try {
            await bdaxios({
                method: 'POST',
                url: '/villages',
                headers: {
                    Authorization: 'Bearer ' + token.value,
                },
                data: village
            });
        } catch (error) {
            if (error.response.status === 400)
                throw (error.response.data.errors[0].msg)
            throw new Error(error.message)
        }

        return this.getVillages();
    }

    async updateVillage(village, token) {
        await bdaxios({
            method: 'PATCH',
            url: '/villages/' + village.id,
            headers: {
                Authorization: 'Bearer ' + token.value,
            },
            data: village
        });
        return this.getVillages();
    }

    async delVillage(id, token) {
        await bdaxios({
            method: 'DELETE',
            url: '/villages/' + id,
            headers: {
                Authorization: 'Bearer ' + token.value,
            },
        });
        return this.getVillages();
    }



}
