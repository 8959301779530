import axios from "axios";


const bdaxios = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
});


export class Artista {


    id = ''
    nombre = 'artista de la pista'
    decada = '80'
    visible = true
    imagen = ''


    async getArtists() {
        const res = await bdaxios.get('/artists/filter');
        return res.data;
    }

    async addArtist(artist, token) {
        try {
            await bdaxios({
                method: 'POST',
                url: '/artists',
                headers: {
                    Authorization: 'Bearer ' + token.value,
                },
                
                data: artist
            });
            return this.getArtists();
        } catch (error) {
            console.log(error.message)
        }
    }

    async updateArtist(id, artist, token) {
        try {
        await bdaxios({
            method: 'PATCH',
            url: '/artists/' + id,
            headers: {
                Authorization: 'Bearer ' + token.value,
            },
            data: artist
        });
        return this.getArtists();
    } catch (error) {
        console.log(error.message)
    }
    }

    async delArtist(id, token) {
        await bdaxios({
            method: 'DELETE',
            url: '/artists/' + id,
            headers: {
                Authorization: 'Bearer ' + token.value,
            },
        });
        return this.getArtists();
    }
}


