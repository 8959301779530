import './listVotes.css'


const ListVotes = ({ votes }) => {


    return (
        <div className='container-list'>

            {votes && votes.length > 0
                ? votes.map((vot, index) =>
                    <div key={index} className='card-items'>

                        <p>
                        <span>{(index+1).toString().padStart(3,'0') } ... </span>{vot.CAN_TITULO}. 
                        <span className='artist'>({vot.ART_NOMBRE})</span> 
                        </p>

                    </div>
                )
                : <div className='list-empty'>
                    <h5>Todavía no hay votos</h5>
                </div>
            }
        </div>
    )
}
export default ListVotes