import { useState, useEffect } from 'react';
import './artist.css'


const ArtistComponent = ({ artist, lstVotos, showListSongs }) => {
 
    const [numVotos, setNumVotos] = useState(0)

    useEffect(() => {
      
        const loadData = async ()=>{

            const myVotes = await lstVotos.filter(item => item.CAN_CLAART === artist.ART_CLAVE)
            setNumVotos(await  myVotes.length)
        }
        loadData();
    }, [lstVotos])
    
 

    return (
        <div className='card' onClick={() => showListSongs(artist)}>
            {(numVotos>0) &&
                <h3 className='badge'>{numVotos}</h3>
            }
            <div className='card-image'>
                {artist.ART_IMAGE
                    ? <img src={process.env.REACT_APP_BASEIMAGE + artist.ART_IMAGE} alt="Logo Poptelera" />
                    : <img src={require('../../assets/musica.jpg')} alt="Logo Artista" />
                }
            </div>
            <div className='card-name'>

            <h4 className='card-text'>
                {artist.ART_NOMBRE}
            </h4>
            </div>


        </div>
    )
}

export default ArtistComponent
