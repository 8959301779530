
import { useContext, useEffect, useState } from 'react';
import { ContextApp } from '../../context/ContextApp';

import './counter.css'

const CounterController = ({ lstVotos }) => {

    const { pueblo } = useContext(ContextApp);
    const [item, setItem] = useState([])

    useEffect(() => {

        const loadData = async () => {

            let datos = [];

            for (let i = 0; i < await process.env.REACT_APP_VOTES_NUMBER; i++) {

                if (i < await lstVotos.length) {
                    datos.push(
                        <div className='contenedor'>
                            <p className='tooltip'>{lstVotos[i].CAN_TITULO}</p>
                            <span className="material-symbols-outlined finish">mail</span>
                        </div>)
                } else
                    datos.push(
                        <span className="material-symbols-outlined">drafts</span>)
            }

            setItem(datos);
        }
        loadData();
    }, [lstVotos])





    return (
        <div className='counter-container'>
            {pueblo &&
                <div className='counter-items'>
                    {item.length > 0 && item.map((item, index) => (
                        <section key={index}>
                            {item}
                        </section>
                    ))
                    }
                </div>
            }
        </div>
    )
}
export default CounterController