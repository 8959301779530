import { getFormatFecha } from '../../helpers/dates'
import './CloseVotes.css'

const PrevVotesComponent = ({ pueblo }) => {
  return (
    <div className="container non">

              <h2 className='textvillage'> Las votaciones todavía no esta abiertas. </h2>

              <div className='logo-container'>
                <img src={require('../../assets/logoPoptelera.png')} alt="Logo Poptelera" />
              </div>
              <h2 className='textvillage'> Se abrirán el  {getFormatFecha(pueblo.PUE_FECOPEN)}</h2>
            </div>
  )
}
export default PrevVotesComponent