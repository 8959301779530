import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ContextApp } from '../../context/ContextApp';

import { useState } from 'react';
import { useEffect } from 'react';
import ListVotes from '../../components/listVotes/ListVotes';

import './results.css'

const Results = () => {

  const { id, datosClass } = useContext(ContextApp);
  const [lstVotos, setLstVotos] = useState();

  useEffect(() => {

    const cargar = async () => {
      setLstVotos(await datosClass.getTotalsVotes(id))
    }
    cargar();

  }, [id, datosClass])

  return (
    <div className='container-result'>
      <p className='mensaje'><Link to={`/${id}`} >Volver a votos</Link></p>

      <div className='card-titles'>
        <p>Las 10 primeras serán las elegidas.</p>
        {/* <p>Votos</p> */}
      </div>
      <ListVotes votes={lstVotos} />
    </div >
  )
}
export default Results